import { Modal, Button } from 'react-bootstrap';

const DeleteModal = ({obj, show, setShow, handleDelete, message}) => {
  const handleClose = () => setShow(false);
  return (
    <Modal 
      size='sm' 
      ari a-labelledby="contained-modal-title-vcenter"
      centered 
      show={show} 
      onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm deletion of this {obj}</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        {/* Once deletion is possible, add into this button */}
        <Button  variant="danger" onClick={handleDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteModal;