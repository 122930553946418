import React, {useState} from 'react';
import { handleLogin } from '../../Services/AuthServices';
import { Container, Form, Button, Row, Col } from "react-bootstrap";

const Login = ({ setToken }) => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [errorMessage, setErrorMessage] = useState();
  let displayErrorMessage;

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin(email, password, setToken, setErrorMessage);
  }

  if (errorMessage) {
    displayErrorMessage = (
      <div>
        <Form.Text style={{color:'#f84672'}}>{errorMessage}</Form.Text><br/>
      </div>
    )
  }

  return(
    <div style = {{height:"100vh", backgroundColor:'black', color:'#f84672'}}>
      <Container>
        <Row className="pt-5 justify-content-center" md={3}>
          <Col>
            <h1 className="mb-5" style={{color: "White"}}>Sign In</h1> 
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Email Address</Form.Label>
                <Form.Control 
                  type="email"
                  placeholder="Enter email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setErrorMessage(undefined);
                  }}
                  style={{backgroundColor: "transparent", color: "white"}}/>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control 
                  type="password"
                  placeholder="Enter password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setErrorMessage(undefined);
                    }} 
                  style={{backgroundColor: "transparent", color: "white"}}/>
              </Form.Group>
              {displayErrorMessage}
              <Button type="submit" className="mt-2" style={{backgroundColor: "#f84672", borderColor: "#f84672"}}>Submit</Button>
            </Form>
          </Col>

        </Row>
      </Container>

    </div>
  )
}

export default Login;
