import React, {useState, useEffect} from "react";
import { Col, Row } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faSave } from '@fortawesome/free-solid-svg-icons';
import { addCredits } from '../../Services/UserService';

const AboutUsers = ({ user }) => {
  const { user_id } = useParams();
  const [email, setEmail] = useState(user.email);
  const [credits, setCredits] = useState(user.credits);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditCreditMode, setIsCreditEditMode] = useState(false);


  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleCreditChange = (event) => {
    setCredits(event.target.value);
  };

  const handleEditButtonClick = () => {
    setIsEditMode(true);
  };

  const handleEditCreditButtonClick = () => {
    setIsCreditEditMode(true);
  };

  const handleSaveButtonClick = () => {
    setIsEditMode(false);
    console.log("save")
    // let user_id = user.user_id;
    // updateUserEmail(user_id, email)
  };

  const handleCancelCreditButtonClick = () => {
    setIsCreditEditMode(false);
  }
  const handleSaveCreditButtonClick = async () => {
    setIsCreditEditMode(false);

    try {
      const response = await addCredits(user_id, credits);
      // Handle the response if needed
      if(response.data.status === 200){
        window.location.reload();
      }
      console.log('Credits added:', response.data.status);
    } catch (error) {
      // Handle error
    }
  };

  

  return (
    <div style={{ borderTop: '1px solid grey' }}>
      <div style={{ marginTop: '20px' }}>
        <Row>
          <Col xs={3}>
            <p style={{ fontWeight: 'bold' }}>Credits:</p>
          </Col>
          <Col>
            {isEditCreditMode ? (
              <input
                type="number"
                value={credits}
                onChange={handleCreditChange}
                className="form-control"
                placeholder="Enter credits to add"
              />
            ) : (
              <p>{user.credits}</p>
            )}
          </Col>
          <Col>
            {/* Render the Edit, Save, or Cancel buttons based on the edit mode */}
            {isEditCreditMode ? (
              <div>
                <button onClick={handleSaveCreditButtonClick}>
                  <FontAwesomeIcon icon={faSave} /> Save
                </button>
                <button onClick={handleCancelCreditButtonClick}>
                  Cancel
                </button>
              </div>
            ) : (
              <button onClick={handleEditCreditButtonClick}>
                <FontAwesomeIcon icon={faPencilAlt} /> Edit
              </button>
            )}
          </Col>
        </Row>
      </div>
      <Row>
        <Col xs={3}>
          <p style={{ fontWeight: 'bold' }}>Email:</p>
        </Col>
        <Col>
          {/* Render the input field in edit mode or display the email */}
          {isEditMode ? (
            <input
              type="email"
              value={user.email}
              onChange={handleEmailChange}
              className="form-control"
            />
          ) : (
            <p>{user.email}</p>
          )}
        </Col>
        <Col>
          {/* Render the Edit or Save button based on the edit mode */}
          {isEditMode ? (
            <button onClick={handleSaveButtonClick}>
              <FontAwesomeIcon icon={faSave} /> Save
            </button>
          ) : (
            <button onClick={handleEditButtonClick}>
              <FontAwesomeIcon icon={faPencilAlt} /> Edit
            </button>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={3}>
          <p style={{ fontWeight: 'bold' }}>Date of Birth:</p>
        </Col>
        <Col>
          <p>{user.dob}</p>
        </Col>
      </Row>
    </div>
  );
};

export default AboutUsers;
