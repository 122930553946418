import { Modal, Button } from 'react-bootstrap';

const ResponseModal = ({message, show, setShow}) => {
  const handleClose = () => setShow(false);
  return (
    <Modal 
      size='sm' 
      ari a-labelledby="contained-modal-title-vcenter"
      centered 
      show={show} 
      onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{message}</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ResponseModal