import { useState } from "react";
import { Row, Col, Button, Dropdown, Alert } from "react-bootstrap";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { SongListGroupItem } from "../styledComponents/SongStyles";
import DeleteModal from "../Shared/DeleteModal";
import { removeSong, setScrybePick, setScrybeRadio } from "../../Services/SongServices";

const Song = ({ song, songs, setSongs, setMessage, setShowResponseModal }) => {
    console.log(song);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showFeaturedBanner, setShowFeaturedBanner] = useState(false);

    const handleShowDeleteModal = () => setShowDeleteModal(true);
    const handleDelete = () => {
        removeSong(song.song_id, setMessage, songs, setSongs, setShowResponseModal);
    };

    const SetScrybePick = async (song, scrybepick) => {
        try {
            scrybepick = scrybepick === '1' ? '0' : '1';
            const response = await setScrybePick(song, scrybepick);
            if (response.status === 200) {
                setShowFeaturedBanner(true);
            } else {
                console.error("Failed to set artist as featured:", response);
            }
        } catch (error) {
            console.error("Failed to set artist as featured:", error);
        }
    };

    const SetRadio = async (song, scrybepick, free) => {
        try {
            scrybepick = scrybepick === '1' ? '0' : '1';
			free = free === '1' ? '0' : '1';
            const response = await setScrybeRadio(song, scrybepick, free);
            if (response.status === 200) {
                setShowFeaturedBanner(true);
            } else {
                console.error("Failed to set artist as featured:", response);
            }
        } catch (error) {
            console.error("Failed to set free", error);
        }
    };

    return (
        <SongListGroupItem>
            {showFeaturedBanner && (
                <Alert variant="warning" onClose={() => setShowFeaturedBanner(false)} dismissible>
                    Song has been updated
                </Alert>
            )}
            <Row>
                <Col xs={2}>
                    <LazyLoadImage
                        src={song.song_thumbnail}
                        height="40"
                        width="40"
                        effect="opacity"
                    />
                </Col>
                <Col xs={8} className="d-flex" style={{ alignItems: 'center' }}>
                    <div style={{ lineHeight: '1.3', fontSize: '14px', height: '30px', display: "inline-flex", alignItems: "center" }}>
                        <span style={{ color: 'grey' }}>{song.song_name}</span>
                    </div> &nbsp;&nbsp;
                    <div style={{ lineHeight: '1.3', fontSize: '14px', height: '30px', display: "inline-flex", alignItems: "center" }}>
                        <span style={{ color: 'grey' }}>listens: {song.total_played}</span>
                    </div>
                </Col>
                <Col xs={2} className="d-flex" style={{ alignItems: 'center' }}>
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {song.scrybepicks ? (
                                <Dropdown.Item onClick={() => SetScrybePick(song.song_id, song.scrybepicks)}>
                                    Remove from Scrybe Picks
                                </Dropdown.Item>
                            ) : (
                                <Dropdown.Item onClick={() => SetScrybePick(song.song_id, song.scrybepicks)}>
                                    Set Scrybe Pick
                                </Dropdown.Item>
                            )}
                            {song.scrybe_radio ? (
                                <Dropdown.Item onClick={() => SetRadio(song.song_id, song.scrybe_radio, song.is_free)}>
                                    Remove from Radio
                                </Dropdown.Item>
                            ) : (
                                <Dropdown.Item onClick={() => SetRadio(song.song_id, song.scrybe_radio, song.is_free)}>
                                    Add To Radio
                                </Dropdown.Item>
                            )}

                            <Dropdown.Item href="#/action-2">Set Free</Dropdown.Item>
                            <Dropdown.Item onClick={handleShowDeleteModal}>Delete</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <DeleteModal
                    obj="song"
                    show={showDeleteModal}
                    setShow={setShowDeleteModal}
                    handleDelete={handleDelete} />
            </Row>
        </SongListGroupItem>
    );
}

export default Song;