import react from 'react'
import axios from "axios";

const API=`https://xfvire5qqc.execute-api.us-west-2.amazonaws.com/production/artist`
const defaultOptions = {
    headers:{
        Authorization: process.env.REACT_APP_BEARER_TOKEN
    }
}

export const getAllArtists = async (setArtists, setLoading) => {

    const res = await axios.get(API + '/get-list-of-artist', defaultOptions);
    setArtists(res.data.data);
    setLoading(false);
}

export const GetArtistProfile = async () =>{
    const res = await axios.get(API + '/get-artist-profile', defaultOptions)
    console.log(res.data)
    return res.data
}

export const getArtistProfile = async (artist_id, setArtist, setLoading) => {

    const res = await axios.get(API + `/get-profile?artist_id=${artist_id}`, defaultOptions);
    setArtist(res.data.data[0]);
    setLoading(false);
}

export const setFeatured = async (artist_id, feature) => {
    //setLoading(true);
    const res = await axios.patch(API + `/set-featured-artist?artist_id=${artist_id}&featured=${feature}`, defaultOptions);
    return res.data
    //setLoading(false)

}
export const GetFeaturedArtists = async () => {
    //setLoading(true);
    const res = await axios.get(API + `/get-featured-artist?type=MUSICIAN`);
    console.log(res.data.data)
    return res.data.data
    //setLoading(false)
}
export const GetFeaturedPodcasters = async () => {
    //setLoading(true);
    const res = await axios.get(API + `/get-featured-artist?type=PODCASTER`);
    console.log(res.data.data)
    return res.data.data
    //setLoading(false)
}

export const updateArtistEmail = async (artist_id, email) => {
    const params = {
        email: email
      };
const res = await axios.put(API + `/update-artist-emailartist_id=${artist_id}`,  {...defaultOptions, params: params,});
}

// export const setFeatured = async (artist_id) => {
//     setLoading(true);
//     const res = await axios.get(API + `/set-featured?artist_id=${artist_id}`, defaultOptions);
//     setArtist(res.data.data[0]);
//     setLoading(false);
// }

