import React, {useState, useEffect} from "react";
import { ListGroup, FormControl, Container, InputGroup, Row } from 'react-bootstrap';
import { getSongsByArtist } from "../../Services/SongServices";
import Song from "../Songs/Song";
import ResponseModal from "../Shared/ResponseModal";
import ReactPaginate from "react-paginate";

const ArtistSongs = ({artist_id, artist}) => {
	const [showResponseModal, setShowResponseModal] = useState(false);
  const [ loadingSongs, setLoadingSongs ] = useState(false);
  const [ pageIndex, setPageIndex ] = useState(1);
  const [ songs, setSongs ] = useState([]);
  const [ message, setMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const pageLimit = 3;
  const [searchBarText, setSearchBarText] = useState("")
  const firstSongIndex = currentPage * pageLimit
  const lastSongIndex = firstSongIndex + pageLimit
  const songsDuplicatesRemoved = songs.filter( (ele, ind) => ind === songs.findIndex( elem => elem.song_id === ele.song_id))

  const changePage = ({selected}) => {
    setCurrentPage(selected)
  }

  useEffect(() => {
    getSongsByArtist(pageIndex, artist_id, artist.artist_type, setLoadingSongs, setSongs);
  }, [artist]) // Wait for artist state to change before running useEffect
  
  const songsFiltered = songsDuplicatesRemoved.filter((val) => {
    if (searchBarText === ""){
      return val;
    }
    else if (val.song_name.toLowerCase().includes(searchBarText.toLowerCase())) {
      return val
    }
  })
  const pageCount = Math.ceil(songsFiltered?.length / pageLimit)

  const displaySongs = songsFiltered
  .slice(firstSongIndex, lastSongIndex) 
  .map((song) => {
      return(
        <Song 
          key={song.song_id} 
          song={song} songs={songs} 
          setSongs={setSongs} 
          setMessage={setMessage} 
          setShowResponseModal={setShowResponseModal}/> 
      )
    })

  return (
    <>
      {artist.artist_type == 'MUSICIAN'? <h2>Songs</h2>: <h2>Podcasts</h2> }
      <Container>
            <FormControl
              placeholder="Song Search"
              aria-label=""
              aria-describedby="basic-addon1"
              onChange={e => {
                setSearchBarText(e.target.value);
              
              }} />
          <ListGroup>
          {loadingSongs
            ? <p>Loading</p>
            : displaySongs
            }
          <ResponseModal 
            message={message}
            show={showResponseModal}
            setShow={setShowResponseModal} />
        </ListGroup>
        <ReactPaginate 
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            />
        </Container>
    </>
  )
}

export default ArtistSongs;