import { Row, Col } from 'react-bootstrap';
import {NavLink} from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { artist_profile_page } from '../../Routes';
import { ArtistListGroupItem } from '../styledComponents/ArtistStyles';


const Artist = ({artist}) => {
	return (
    <NavLink to={artist_profile_page.generate(artist.artist_id)} style={{textDecoration: 'none'}}> 
      <ArtistListGroupItem>
        <Row style={{height: '50px'}}>
          <Col xs={1}>
            {artist.profile_pic
              ? <LazyLoadImage 
              src={artist.profile_pic} 
              height="50"
              width="50"
              effect="opacity"/>
              : 
              <div className='d-flex' style={{background: 'grey', height: '50px', width: '50px', justifyContent: 'center', alignItems: 'center'}}>
                <i class="fa-solid fa-user" style={{color: 'white'}}></i>
              </div>
            }
            
          </Col>
          <Col xs={10}>
            <div style={{lineHeight: '0.5'}}>
              <h4 style={{color: 'grey'}}>{artist.fullname}</h4>
              <p style={{color: 'grey'}}>{artist.artist_type}</p>
            </div>
          </Col>
          <Col xs={1}>
            <div style={{textAlign: 'right'}}>
              <i class="fa-solid fa-chevron-right" style={{marginTop: '18px', color: 'grey'}}></i>
            </div>
          </Col>
        </Row>
      </ArtistListGroupItem>
    </NavLink>
	)   
}

export default Artist