import {generatePath} from "react-router-dom";

export const home_page = {
    route: "/",
    generate() {
        return generatePath(this.route)
    }
}

export const artists_page = {
  route: "/artists",
  generate() {
      return generatePath(this.route)
  }
}
export const artist_profile_page = {
  route: "/artist/:artist_id",
  generate(artist_id) {
      return generatePath(this.route, {artist_id})
  }
}
export const users_page = {
  route: "/users",
  generate() {
      return generatePath(this.route)
  }
}
export const user_profile_page = {
  route: "/user/:user_id",
  generate(user_id) {
      return generatePath(this.route, {user_id})
  }
}