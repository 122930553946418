import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import { Col, Row, Button, Stack, Alert } from 'react-bootstrap'
import { getArtistProfile, setFeatured } from "../../Services/ArtistsService";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import AboutArtist from "./AboutArtist"
import DeleteModal from "../Shared/DeleteModal";
import ArtistSongs from "./ArtistSongs";

const ArtistProfile = () => {
  const [ artist, setArtist ] = useState([]);
  const [ loadingArtistProfile, setLoadingArtistProfile ] = useState(false);
  const [showFeaturedBanner, setShowFeaturedBanner] = useState(false);
  const { artist_id } = useParams();
  const [show, setShow] = useState(false);

  const leftSectionScreenSize = {
    xs: 3
  };  
  const midSectionScreenSize = {
    xs: 5
  };
  const rightSectionScreenSize = {
    xs: 4
  };

  useEffect(() => {
    getArtistProfile(artist_id, setArtist, setLoadingArtistProfile);
  }, [])

  
  const handleShow = () => setShow(true);
  
  const submitSetFeatured = async (artist, featured) => {
    try {
      if(featured == '1'){
        featured = '0'
      } else { 
        featured = '1'
      }
      console.log(featured)
      const response = await setFeatured(artist, featured);
      if(response.status === 200){
        getArtistProfile(artist_id, setArtist, setLoadingArtistProfile);
      }
      if (response.status === 200) {
        setShowFeaturedBanner(true);
      } else {
        console.error("Failed to set artist as featured:", response);
      }
    } catch (error) {
      console.error("Failed to set artist as featured:", error);
    }
  };
  return (
    <div style={{backgroundColor: 'black', padding: '0 60px 0 0', color: 'grey', height: '1000px'}}>
            {showFeaturedBanner && (
        <Alert variant="success" onClose={() => setShowFeaturedBanner(false)} dismissible>
          Artist is now being featured!
        </Alert>
      )}
      <Row style={{paddingTop: '60px'}}>
        <Col xs={leftSectionScreenSize['xs']}>
          <div style={{display: 'block', height: '200px', width: '200px', margin: 'auto'}}>
            <Stack>
              <LazyLoadImage 
                className="profile-page-img"
                src={artist.profile_pic} 
                height="200"
                width="200"
                effect="opacity"
                style={{borderRadius: '10px'}}/>
                <div style={{marginTop: '10px', textAlign: 'left'}}>
                  <Button style={{paddingTop: '5px', padding: '10px'}} variant="secondary" onClick={handleShow} disable>
                    Delete Artist
                  </Button>
                  <DeleteModal 
                    obj="artist"
                    show={show}
                    setShow={setShow}/>
                </div>
                <div style={{marginTop: "15px"}}>
                {artist.isfeatured == '1' ? (
                  <Button style={{backgroundColor: "#f84672"}} onClick={() => submitSetFeatured(artist_id, artist.isfeatured)}>Unset Featured</Button>
                ) : (
                  <Button style={{backgroundColor: "#f84672", borderColor: "#f84672"}} onClick={() => submitSetFeatured(artist_id, artist.isfeatured)}>Set as Featured</Button>
                )}
              </div>
            </Stack>
          </div>
        </Col>
        <Col xs={midSectionScreenSize['xs']}>
          <h1 style={{color: 'white'}}>{artist.fullname}</h1>
          <p>{artist.artist_type}</p>
          <p><b>Subscrybers: </b>{artist.subscribers}</p>
        </Col>
        <Col xs={rightSectionScreenSize['xs']}>
          <ArtistSongs 
            artist_id={artist_id}
            artist={artist}
            />
        </Col>
      </Row>
      <Row style={{marginTop: '20px'}}>
        <Col xs={leftSectionScreenSize['xs']}>
        </Col>
        <Col xs={midSectionScreenSize['xs']}>
          <p style={{fontSize: '16px', lineHeight: '0.5'}}>About Artist</p>
          <AboutArtist artist={artist}/>
        </Col>
        <Col xs={rightSectionScreenSize['xs']}>
        </Col>
      </Row>
    </div>
  )  
}

export default ArtistProfile