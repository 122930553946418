import react, { useEffect, useState } from 'react'
import {ListGroup, Container, DropdownButton, ButtonGroup, Dropdown, Row, Col } from 'react-bootstrap';
import Divider from "@material-ui/core/Divider";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ScrybePicks = ({songs}) => {

	return(
       
        <Container style={{backgroundColor:'black'}}>
                <h2 style={{backgroundColor:'black', color:'#f84672'}} >Scrybe Picks</h2>
        <ListGroup  style={{backgroundColor: 'black', borderColor: '#f84672', marginRight: '5%',
            maxHeight: '300px',
            marginBottom: '10px',
            overflow: 'scroll',
            width: '100%' }}>
               
            <ListGroup.Item  style={{backgroundColor:'black', color:'#f84672'}}>
                
                    {!songs ? "there was error retrieving songs": songs.map((song) =>
                    
                    <Row style={{height: '100px'}}>
                        <Col xs={1}>
                            {song.song_thumbnail
                                ? <LazyLoadImage 
                                src={song.song_thumbnail} 
                                height="75"
                                width="75"
                                effect="opacity"/>
                                : 
                                <div className='d-flex' style={{background: 'grey', height: '50px', width: '50px', justifyContent: 'center', alignItems: 'center'}}>
                                    <i class="fa-solid fa-user" style={{color: 'white'}}></i>
                                </div>
                                } 
                        </Col>

                    <Col xs={10}>
                        <div style={{lineHeight: '0.5', paddingLeft: '8%'}}>
                            <h5  style={{color: 'grey', fontSize: '20px'}}>&nbsp;&nbsp;{song.song_name} &nbsp;&nbsp;</h5>
                            <p style={{color: 'grey', fontSize: '15px'}}>&nbsp;&nbsp;{song.artist_name} &nbsp;&nbsp;</p>
                            <div style={{textAlign: 'right'}}>
                                <p style={{fontSize: '15px'}}> Listens:&nbsp;&nbsp; {song.total_played}</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={1}>
                        <div style={{textAlign: 'right'}}>
                            <DropdownButton as={ButtonGroup} variant="black">
                                <Dropdown.Item eventKey="1">Delete</Dropdown.Item>
                            </DropdownButton> 
                        </div>
                    </Col>
                    <Divider style={{backgroundColor:'#f84672', height:'1px'}}/>
                    
                

                </Row> )}
            </ListGroup.Item>
        </ListGroup>
        </Container>
       
        
    )
}

export default ScrybePicks
