import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import { Col, Row, Button, Stack, Alert } from 'react-bootstrap'
import { getUserProfile, setFeatured, deleteUser } from "../../Services/UserService";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import AboutUsers from "./AboutUsers"
import DeleteModal from "../Shared/DeleteModal";


const UserProfile = () => {
  const [ user, setUser ] = useState([]);
  const [ loadingUserProfile, setLoadingUserProfile ] = useState(false);
  const [showFeaturedBanner, setShowFeaturedBanner] = useState(false);
  const { user_id } = useParams();
  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const leftSectionScreenSize = {
    xs: 3
  };  
  const midSectionScreenSize = {
    xs: 5
  };
  const rightSectionScreenSize = {
    xs: 4
  };

  useEffect(() => {
    getUserProfile(user_id, setUser, setLoadingUserProfile);
  }, [])

  const handleDeleteUser = () => {
    deleteUser(user_id)
      .then(() => {
        setShowAlert(true);
      })
      .catch((error) => {
        // handle error here
      });
  };

  
  return (
    <div style={{backgroundColor: 'black', padding: '0 60px 0 0', color: 'grey', height: '1000px'}}>
          {showAlert && 
      <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
        User has been deleted successfully.
      </Alert>
    }
      <Row style={{paddingTop: '60px'}}>
        <Col xs={leftSectionScreenSize['xs']}>
          <div style={{ margin: 'auto', paddingLeft: '30px'}}>
            <Stack>
              <LazyLoadImage 
                className="profile-page-img"
                src={user.profile_pic} 
                height="300"
                width="300"
                effect="opacity"
                style={{borderRadius: '10px'}}/>
                <div style={{marginTop: '10px', textAlign: 'left'}}>
                <Button style={{paddingTop: '5px', padding: '10px'}} variant="secondary" onClick={handleDeleteUser} >
                  Delete User
                </Button>
                </div>

            </Stack>
          </div>
        </Col>
        <Col xs={midSectionScreenSize['xs']}>
          <h1 style={{color: 'white'}}>{user.fullname}</h1>
          <p>{user.user_type}</p>
        </Col>
        <Col xs={rightSectionScreenSize['xs']}>
        </Col>
      </Row>
      <Row style={{marginTop: '20px'}}>
        <Col xs={leftSectionScreenSize['xs']}>
        </Col>
        <Col xs={midSectionScreenSize['xs']}>
          <p style={{fontSize: '16px', lineHeight: '0.5'}}>About User</p>
          <AboutUsers user={user}/>
        </Col>
        <Col xs={rightSectionScreenSize['xs']}>
        </Col>
      </Row>
    </div>
  )  
}

export default UserProfile