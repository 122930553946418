
import {ListGroup, Container, DropdownButton, ButtonGroup, Dropdown, Row, Col, Alert } from 'react-bootstrap';
import React, {useState, useEffect} from "react";
import axios from 'axios';
import {setFeatured } from "../Services/ArtistsService";
import Divider from "@material-ui/core/Divider";
import { LazyLoadImage } from 'react-lazy-load-image-component';


const FeaturedArtists = ({ featuredArtist }) => {
  const [showFeaturedBanner, setShowFeaturedBanner] = useState(false);
    const unsetFeatured = async (artistId) => {
      try {
          let featured = '0'
        console.log(featured)
        const response = await setFeatured(artistId, featured);
        if(response.status === 200){;
        }
        if (response.status === 200) {
          setShowFeaturedBanner(true);
        } else {
          console.error("Failed to set artist as featured:", response);
        }
      } catch (error) {
        console.error("Failed to set artist as featured:", error);
      }
    };
  
    useEffect(() => {
      if (showFeaturedBanner) {
        // Automatically hide the banner after 3 seconds
        const timeout = setTimeout(() => {
          setShowFeaturedBanner(false);
        }, 3000);
  
        // Clear the timeout when the component unmounts to avoid memory leaks
        return () => {
          clearTimeout(timeout);
        };
      }
    }, [showFeaturedBanner]);
    return (
      <Container style={{ backgroundColor: 'black' }}>
        <h2 style={{ backgroundColor: 'black', color: '#f84672' }}>Featured Artists/Podcasters</h2>
        {showFeaturedBanner && (
        <Alert variant="warning" onClose={() => setShowFeaturedBanner(false)} dismissible>
          Artist has been Unfeatured
        </Alert>
      )}
        <ListGroup style={{ backgroundColor: 'black', borderColor: '#f84672', marginRight: '5%', maxHeight: '300px', marginBottom: '10px', overflow: 'scroll', width: '100%' }}>
          {featuredArtist ? (
            featuredArtist.map((artist) => (
              <ListGroup.Item style={{ backgroundColor: 'black', color: '#f84672' }}>
                <Row style={{ height: '100px' }}>
                  <Col xs={1}>
                        <Col xs={1}>
                            {artist.profile_pic
                                ? <LazyLoadImage 
                                src={artist.profile_pic} 
                                height="75"
                                width="75"
                                effect="opacity"/>
                                : 
                                <div className='d-flex' style={{background: 'grey', height: '50px', width: '50px', justifyContent: 'center', alignItems: 'center'}}>
                                    <i class="fa-solid fa-user" style={{color: 'white'}}></i>
                                </div>
                                } 
                        </Col>
                  </Col>
                  <Col xs={10}>
                  <Col xs={10}>
                        <div style={{lineHeight: '0.5', paddingLeft: '8%'}}>
                            <h5  style={{color: 'grey', fontSize: '20px'}}>&nbsp;&nbsp;{artist.fullname} &nbsp;&nbsp;</h5>
                            <p style={{color: 'grey', fontSize: '15px'}}>&nbsp;&nbsp;{artist.content_desription} &nbsp;&nbsp;</p>
                            <div style={{textAlign: 'right'}}>
                                <p style={{fontSize: '15px'}}> Listens:&nbsp;&nbsp; {artist.earnings_left}</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={1}>
                        <div style={{textAlign: 'right'}}>
                            <DropdownButton as={ButtonGroup} variant="black">
                                <Dropdown.Item eventKey="1">Delete</Dropdown.Item>
                            </DropdownButton> 
                        </div>
                    </Col>
                    <Divider style={{backgroundColor:'#f84672', height:'1px'}}/>
                  </Col>
                  <Col xs={1}>
                    <div>
                      <DropdownButton as={ButtonGroup}>
                        <Dropdown.Item eventKey="2" onClick={() => unsetFeatured(artist.artist_id)}>Unset Featured</Dropdown.Item>
                      </DropdownButton> 
                    </div>
                  </Col>
                </Row>
              </ListGroup.Item>
            ))
          ) : (
            <ListGroup.Item style={{ backgroundColor: 'black', color: '#f84672' }}>There was an error retrieving artists</ListGroup.Item>
          )}
        </ListGroup>
      </Container>
    );
  };
  
  export default FeaturedArtists;
