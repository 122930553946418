import {useState} from 'react'
import axios from "axios";

const API=`https://xfvire5qqc.execute-api.us-west-2.amazonaws.com/production/artist`
const tokenName = 'DUID'

// For now, we will have a list of emails that can enter the Admin Panel. Later on, we will probably add a 'admin' field to the user table
const admins = [
  'cphyfier@gmail.com', 
  'Christian@scrybestreaming.com',
  'jaydenowens23@gmail.com', 
  'dallynowens2@gmail.com',
  'jigar@mynextdeveloper.com'
]

export const handleLogin = (email, password, setToken, setErrorMessage) => {
  const headers = { 'Content-Type': 'application/json' };
  const data = {
    email: email,
    password: password,
  };

  axios.post(API + '/signin', data, { headers })
    .then(res => {
      if (res.data.status == 200 && res.data.data[0].accesstoken) {
        if (admins.includes(res.data.data[0].email)) {
          setToken(res.data.data[0].accesstoken);
        } else {
          setErrorMessage("Sorry, this user is not permitted to enter the Admin Panel");
        }
      }
    })
    .catch(err => {
      setErrorMessage(err.response.data.message);
    });
}


export const handleLogout = () => {
  localStorage.clear();
  window.location.href = '/';
}

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem(tokenName);
    const userToken = JSON.parse(tokenString);
    return userToken
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    localStorage.setItem(tokenName, JSON.stringify(userToken));
    setToken(userToken);
  }
  
  return {
    setToken: saveToken,
    token
  }
}