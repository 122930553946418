import "./App.css";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Components/Home'
import Artists from "./Components/Artists/Artists";
import Navbars from "./Components/NavBar";
import ArtistProfile from './Components/Artists/ArtistProfile';
import Login from './Components/Auth/Login';
import useToken from './Services/AuthServices';
import Users from './Components/Users/Users';
import UserProfile from './Components/Users/UserProfile';
import { home_page, artists_page, artist_profile_page, users_page, user_profile_page } from './Routes';


const App = () => {
 const { token, setToken } = useToken();

  if(!token) {
    return <Login setToken={setToken} />
  }

  return(
    <Router>
      <Navbars/>
      <Routes>
        <Route path={home_page.route} element={<Home/>} />
        <Route path={artists_page.route} element={<Artists/>} />
        <Route path={artist_profile_page.route} element={<ArtistProfile/>} />
        <Route path={users_page.route} element={<Users/>} />
        <Route path={user_profile_page.route} element={<UserProfile/>} />
      </Routes> 
    </Router>
  )
};

export default App;
