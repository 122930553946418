import { useEffect, useState } from 'react'
import { InputGroup, FormControl, Row, Container, ListGroup } from 'react-bootstrap';
import User from "./User";
import { getAllUsers } from "../../Services/UserService"
import UserProfile from './UserProfile';
import ReactPaginate from "react-paginate";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchBarText, setSearchBarText] = useState("")
  const pageLimit = 100
  // Get rid of duplicates from data
  const usersDuplicatesRemoved = users.filter( (ele, ind) => ind === users.findIndex( elem => elem.user_id === ele.user_id))
  const firstUserIndex = currentPage * pageLimit
  const lastUserIndex = firstUserIndex + pageLimit

  const changePage = ({selected}) => {
    setCurrentPage(selected)
  }

  useEffect(() => {
    getAllUsers(setUsers, setLoading)
  }, [])

  const usersFiltered = usersDuplicatesRemoved.filter((val) => {
    if (searchBarText === ""){
      return val;
    }
    else if (val.fullname.toLowerCase().includes(searchBarText.toLowerCase())) {
      return val
    }
  })
  const pageCount = Math.ceil(usersFiltered?.length / pageLimit)



const displayUsers = usersFiltered
  ? usersFiltered
      .slice(firstUserIndex, lastUserIndex)
      .map((user) => <User key={user.user_id} user={user} /> )
  : null;

  return (
    <div style={{backgroundColor:'black', color:'#f84672',}}>
      {/* Search Bar */}
      <Container >
        <Row xs={6} md={4} style={{marginBottom: '10px'}}>
          <InputGroup className="mb-3">
            <InputGroup.Text 
              id="basic-addon1" 
              style={{color:'#f84672', backgroundColor:'black'}}>
              <i class="fa-solid fa-magnifying-glass"></i>
            </InputGroup.Text>
            <FormControl
              placeholder="User Search"
              aria-label=""
              aria-describedby="basic-addon1"
              onChange={e => {
                setSearchBarText(e.target.value);
              }} />
          </InputGroup>
        </Row>
        {/* User Cards */}
        <ListGroup>
          {loading
            ? <p>Loading</p>
            : displayUsers
            }
        </ListGroup>
        <ReactPaginate 
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          />
      </Container>      
    </div>    
  )
}
export default Users;