import { useEffect, useState } from 'react'
import { InputGroup, FormControl, Row, Container, ListGroup } from 'react-bootstrap';
import Artist from "./Artist";
import { getAllArtists } from "../../Services/ArtistsService"
import ReactPaginate from "react-paginate";

const Artists = () => {
  const [artists, setArtists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchBarText, setSearchBarText] = useState("")
  const pageLimit = 10
  // Get rid of duplicates from data
  const artistsDuplicatesRemoved = artists.filter( (ele, ind) => ind === artists.findIndex( elem => elem.artist_id === ele.artist_id))
  const firstArtistIndex = currentPage * pageLimit
  const lastArtistIndex = firstArtistIndex + pageLimit

  const changePage = ({selected}) => {
    setCurrentPage(selected)
  }

  useEffect(() => {
    getAllArtists(setArtists, setLoading)
  }, [])

  const artistsFiltered = artistsDuplicatesRemoved.filter((val) => {
    if (searchBarText === ""){
      return val;
    }
    else if (val.fullname.toLowerCase().includes(searchBarText.toLowerCase())) {
      return val
    }
  })
  const pageCount = Math.ceil(artistsFiltered?.length / pageLimit)

  const displayArtists = artistsFiltered
    .slice(firstArtistIndex, lastArtistIndex)
    .map((artist) => {
      return(
        <Artist key={artist.artist_id} artist={artist} />
      )
    })

  return (
    <div style={{backgroundColor:'black', color:'#f84672',}}>
      {/* Search Bar */}
      <Container >
        <Row xs={6} md={4} style={{marginBottom: '10px'}}>
          <InputGroup className="mb-3">
            <InputGroup.Text 
              id="basic-addon1" 
              style={{color:'#f84672', backgroundColor:'black'}}>
              <i class="fa-solid fa-magnifying-glass"></i>
            </InputGroup.Text>
            <FormControl
              placeholder="Artist Search"
              aria-label=""
              aria-describedby="basic-addon1"
              onChange={e => {
                setSearchBarText(e.target.value);
              }} />
          </InputGroup>
        </Row>
        {/* Artist Cards */}
        <ListGroup>
          {loading
            ? <p>Loading</p>
            : displayArtists
            }
        </ListGroup>
        <ReactPaginate 
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          />
      </Container>      
    </div>    
  )
}
export default Artists;