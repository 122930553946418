import react from 'react'
import { useEffect, useState } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Card from 'react-bootstrap/Card'
import { getAllUsers } from '../Services/UserService'

const Subscriptions = (totalUsers) => {
  const [users, setUsers] = useState([])

  useEffect(() => {
    getAllUsers(setUsers)
  }, [])
    const songs = '100'
    const Users = users.length +1
    const UserGoal = 100000
    const UserPercentage = (Users/UserGoal)*100 
    return(

        <Card style={{ width: '20rem', backgroundColor:'black'}}>
  <Card.Body>
    <Card.Title style={{color:'#f84672'}}><i className="fa-solid fa-bullseye"></i></Card.Title>
    <Card.Subtitle style={{color:'#f84672'}}></Card.Subtitle>
    <Card.Subtitle style={{color:'#f84672'}}>{songs}</Card.Subtitle>
    <Card.Text>
    <ProgressBar striped variant="info"style={{backgroundColor:'black', color:'#f84672'}} now={20} />
    </Card.Text>
    <Card.Subtitle style={{color:'#f84672'}}>Users: {Users} Goal: {UserGoal}</Card.Subtitle>
    <Card.Text>
    <ProgressBar striped variant="danger" style={{backgroundColor:'black', color:'#f84672'}} now={UserPercentage} />
    </Card.Text>
  </Card.Body>
</Card>

    )
    
}

export default Subscriptions;