import { useEffect, useState } from 'react'
import Subscriptions from "./Subscriptions";
import NewSongs from "./NewSongs";
import TopSongs from "./TopSongs";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GetNewSongs, GetDailyPopularSong, GetScrybePicks } from '../Services/SongServices';
import { GetFeaturedArtists, GetFeaturedPodcasters } from '../Services/ArtistsService'
import ScrybePicks from './ScrybePicks';
import FeaturedArtists from './FeaturedArtists';

const Home = () => {
    const [songs, setSongs] = useState([])
    const getNewSongs = () => {
        GetNewSongs().then((sng) => setSongs(sng))
    }
    useEffect(() => {
        getNewSongs()
    }, [])

    const [topSong, setTopSong] = useState([])
    const getDailyPopularSong = () => {
        GetDailyPopularSong().then((sng) => setTopSong(sng))
    }
    useEffect(() => {
        getDailyPopularSong()
    }, [])

    const [scrybePick, setScrybePick] = useState([])
    const getScrybePicks = () => {
      GetScrybePicks().then((sngs) => setScrybePick(sngs))
    }
    useEffect(() => {
      getScrybePicks()
    }, [])


    const [featuredArtist, setFeaturedArtists] = useState([])
    const getFeaturedArtists = () => {
      GetFeaturedArtists().then((sng) => setFeaturedArtists(sng))
    }
    useEffect(() => {
      getFeaturedArtists()
    }, [])

    const [featuredPodcasters, setFeaturedPodcasters] = useState([])
    const getFeaturedPodcasters = () => {
      GetFeaturedPodcasters().then((sng) => setFeaturedPodcasters(sng))
    }
    useEffect(() => {
      getFeaturedPodcasters()
    }, [])

return(  
    <div style={{ backgroundColor: "black", height: 2000 }}>
      <br />
      <br />
      <Container style={{ backgroundColor: "black", height: 1000}}>
        <Row style={{float: 'right'}}>
          <Col>
            <Subscriptions/>
          </Col>
        </Row>
        <Row >
          <Col>
            <NewSongs songs={songs.data}/>
          </Col>
        </Row>
        <Row style={{float: 'left', position: 'absolute', paddingTop: '30%'}}>
          <Col>
          <TopSongs topSong={topSong.data}/>
          </Col>
          <Col>
            <ScrybePicks songs={scrybePick.data}/>
          </Col>
        </Row>
        <Row >
          <Col>
          <FeaturedArtists featuredArtist={featuredArtist}/>
          </Col>
          <Col>
          <FeaturedArtists featuredArtist={featuredPodcasters}/>
          </Col>
        </Row>

      </Container>
    </div>
  );
};

export default Home;
