import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faSave } from '@fortawesome/free-solid-svg-icons';
import {updateArtistEmail} from '../../Services/ArtistsService'

const AboutArtist = ({ artist }) => {
  const [email, setEmail] = useState(artist.email);
  const [isEditMode, setIsEditMode] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleEditButtonClick = () => {
    setIsEditMode(true);
  };

  const handleSaveButtonClick = () => {
    setIsEditMode(false);
    console.log("save")
    // let artist_id = artist.artist_id;
    // updateArtistEmail(artist_id, email)
  };

  

  return (
    <div style={{ borderTop: '1px solid grey' }}>
      <div style={{ marginTop: '20px' }}>
        <Row>
          <Col xs={3}>
            <p style={{ fontWeight: 'bold' }}>Phone:</p>
          </Col>
          <Col>
            <p>{artist.phone}</p>
          </Col>
        </Row>
      </div>
      <Row>
        <Col xs={3}>
          <p style={{ fontWeight: 'bold' }}>Email:</p>
        </Col>
        <Col>
          {/* Render the input field in edit mode or display the email */}
          {isEditMode ? (
            <input
              type="email"
              value={email}
              onChange={handleEmailChange}
              className="form-control"
            />
          ) : (
            <p>{email}</p>
          )}
        </Col>
        <Col>
          {/* Render the Edit or Save button based on the edit mode */}
          {isEditMode ? (
            <button onClick={handleSaveButtonClick}>
              <FontAwesomeIcon icon={faSave} /> Save
            </button>
          ) : (
            <button onClick={handleEditButtonClick}>
              <FontAwesomeIcon icon={faPencilAlt} /> Edit
            </button>
          )}
        </Col>
      </Row>
      <Row>
      <Col xs={3}>
          <p style={{ fontWeight: 'bold' }}>Website:</p>
        </Col>
        <Col>
          <p>{artist.website}</p>
        </Col>
      </Row>
      <Row>
        <Col xs={3}>
          <p style={{ fontWeight: 'bold' }}>Date of Birth:</p>
        </Col>
        <Col>
          <p>{artist.dob}</p>
        </Col>
      </Row>
    </div>
  );
};

export default AboutArtist;
