import { Navbar, Nav } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Logo from "../images/logo.png";
import { Link } from "react-router-dom";
import { handleLogout } from "../Services/AuthServices";
import { home_page, artists_page, user_profile_page, users_page } from "../Routes";

const Navbars = () => (
  <Navbar bg="dark" variant="dark">
    <Container>
      <Navbar.Brand href={home_page.route}>
        <img
          src={Logo}
          width="100"
          height="30"
          className="d-inline-block align-top"
          alt="React Bootstrap logo"
          style={{ textColor: "#f84672" }}
          color="#f84672"
          />
      </Navbar.Brand>
      <Nav className="me-auto">
        <Nav.Link href={home_page.route} style={{ color: "#f84672" }}>
          <i class="fa-solid fa-money-bill"></i> Home
        </Nav.Link>
        <Nav.Link href={artists_page.route} style={{ color: "#f84672" }}>
          <i class="fa-solid fa-user"></i> Artists
        </Nav.Link>
        <Nav.Link href={users_page.route} style={{ color: "#f84672" }}>
          <i class="fa-solid fa-users"></i> Users
        </Nav.Link>
      </Nav>
      <Nav>
        <Nav.Item 
          onClick={() => handleLogout()}
          style={{ color: "#f84672", cursor: 'pointer' }}>
          <i class="fa-solid fa-right-from-bracket"></i> Logout
        </Nav.Item>
      </Nav>
    </Container>
  </Navbar>
)

export default Navbars