import react from 'react'
import axios from "axios";

const API=`https://8enzmx0q7g.execute-api.us-west-2.amazonaws.com/production/user`
const API2 =`https://xfvire5qqc.execute-api.us-west-2.amazonaws.com/production/artist`


const defaultOptions = {
    headers:{
        Authorization: localStorage.getItem('DUID')
    }
  }

export const getAllUsers = async (setUsers, setLoading) => {

    const res = await axios.get(API + '/get-users', defaultOptions);
    setUsers(res.data.data);

}

export const getUserProfile = async (user_id, setUser, setLoading) => {

    const res = await axios.get(API2 + `/get-user-profile?user_id=${user_id}`, defaultOptions);
    setUser(res.data.data[0]);
    setLoading(false);
}

export const addCredits = async (user_id, credits) => {
    console.log(user_id)
    const data = { 'credits': credits, "user_id": user_id, "admin": true}; 
    try {
      const res = await axios.put(API + '/admin-add-credits', data, defaultOptions);
      return res;
    } catch (error) {
      // Handle errors
      console.error('Error adding credits:', error);
      throw error;
    }
};

export const deleteUser = async (user_id) => {
  console.log(defaultOptions);
  const data = { "user_id": user_id, "email": "jaydenowens23@gmail.com" }; 
  try {
    const res = await axios.delete(API + '/admin-delete-user', { ...defaultOptions, data });
    console.log(res);
  } catch (error) {
    // Handle errors
    console.error('Error deleting user:', error);
    throw error;
  }
};


