import axios from "axios";

const API=`https://q43d87n75l.execute-api.us-west-2.amazonaws.com/production/media`
let access_token = JSON.parse(localStorage.getItem('DUID'))
const defaultOptions = {
	headers:{
			Authorization: `Bearer ${access_token}`
	}
}
export const GetNewSongs = async () =>{
	const res = await axios.get(API + '/get-new-songs?page=1&type=SONG', defaultOptions)
	return res.data
}

export const GetDailyPopularSong = async () =>{
	const res = await axios.get(API + '/get-daily-popular-song?page=1&type=SONG', defaultOptions)

	return res.data
}

export const GetScrybePicks = async () =>{
	const res = await axios.get(API + '/get-scrybe-picks?type=SONG', defaultOptions)
	console.log(res.data)
	return res.data
}

export const setScrybeRadio = async (song_id, scrybepick, free) => {
    //setLoading(true);
    const res = await axios.post(API + `/set-free-vybe?song_id=${song_id}&radio=${scrybepick}&is_free=${free}`, defaultOptions);
    return res.data
    //setLoading(false)
}

export const setScrybePick = async (song_id, is_free) => {
    //setLoading(true);
    const res = await axios.post(API + `/set-free-vybe?song_id=${song_id}&is_free=${is_free}`, defaultOptions);
    return res.data
    //setLoading(false)
}

export const getSongsByArtist = async (pageIndex, artistId, type, setLoading, setSongs) => {
	setLoading(true);
	type = type === 'MUSICIAN' ? 'SONG' : 'PODCAST';
	const res = await axios.get(API + `/get-songs-by-artist?page=${pageIndex}&artist_id=${artistId}&type=${type}`, defaultOptions);
	setSongs(res.data.data);
	setLoading(false);
}

export const removeSong = async (song_id, setResponse, songs, setSongs, setShowResponseModal) => {
	let data = { song_id: song_id };
	let tokenString = localStorage.getItem('DUID')
	let defaultOptions = {
		headers: {'Authorization': JSON.parse(tokenString)},
		data: JSON.stringify(data)
	}
	const res = await axios.delete(API + `/remove-song`, defaultOptions);
	if (res.status == 200) {
		setSongs(songs.filter((s) => s.song_id !== song_id));
		setResponse(res.data.message);
		setShowResponseModal(true);
	}
	else {
		setResponse("An error occurred")
	}
}